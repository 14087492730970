body {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for fading out */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Class for fade-in effect */
.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

/* Class for fade-out effect */
.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}